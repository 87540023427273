<template>
  <!-- 号码池页面 -->
  <div class="number">
    <!-- 操作区域 -->
    <el-row>
      <el-col :span="12">
        <div class="title-name">
          <span>回收号码列表</span>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="title-button">
          <div class="ml-10">
            <el-button icon="el-icon-search" class="button-el" size="medium" @click="theQueryDialog=true">查询</el-button>
          </div>
          <div class="ml-10">
            <el-dropdown>
              <el-button class="button-el" size="medium">
                <icon-font type="icon-daochushuju" class="button-icon-font" />
                导出数据
                <icon-font type="icon-copy-4-36" class="button-icon-font" />
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <!-- <el-dropdown-item @click="setSearchExport(0)">全部</el-dropdown-item> -->
                  <!-- <el-dropdown-item @click="setSearchExport(1)">选定</el-dropdown-item> -->
                  <el-dropdown-item @click="setSearchExport(2)">搜索结果</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <div class="ml-10">
            <el-tooltip content="刷新列表" placement="top" effect="light">
              <div class="font-refresh-out flex-nowrap-center-center" @click="searchTapReset(1)">
                <icon-font v-if="loading==false" type="icon-shuaxin" class="font-refresh" />
                <i v-else class="el-icon-loading loding-color"></i>
              </div>
            </el-tooltip>
          </div>
          <!-- new -->
        </div>
      </el-col>
    </el-row>
    <!-- 数据列表 -->
    <el-row>
      <el-col :span="24">
        <div>
          <el-table v-loading="loading" ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" max-height="700" size="mini" :row-style="{height:'50px'}"
            :cell-style="{padding:'0px'}" :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column label="回收号码" fixed width="130px">
              <template #default="scope">{{ scope.row.number == null ? "-" : scope.row.number }}</template>
            </el-table-column>
            <el-table-column label="地区" width="130">
              <template #default="scope">{{ scope.row.province == null ? "-" : scope.row.province }}、{{ scope.row.city == null ? "-" : scope.row.city }}</template>
            </el-table-column>
            <el-table-column label="回收时间" width="140">
              <template #default="scope">{{ scope.row.createTime == null?'-': scope.row.createTime}}</template>
            </el-table-column>
            <el-table-column label="前所属用户" width="120px">
              <template #default="scope">{{ scope.row.nickName == null ? "-" : scope.row.nickName }}</template>
            </el-table-column>
            <el-table-column label="服务商" width="130">
              <template #default="scope">{{ scope.row.amountName == null ? "-" : scope.row.amountName }}</template>
            </el-table-column>
            <el-table-column label="接口类型" width="180">
              <template #default="scope">{{ scope.row.interfaceName == null?'-': scope.row.interfaceName}}</template>
            </el-table-column>
            <el-table-column label="创建时间" width="180">
              <template #default="scope">{{ scope.row.numberCreateTime == null?'-': scope.row.numberCreateTime}}
              </template>
            </el-table-column>
            <el-table-column label="是否退网" width="110px">
              <template #default="scope">
                <span >{{ scope.row.withdrawNetwork?'是':'否' }}</span>
              </template>
            </el-table-column>
            <el-table-column label="计费类型/是否录音" width="130px">
              <template #default="scope">
                <div v-if="scope.row.type === 1">
                  <el-tag size="mini">单计费</el-tag><span :class="scope.row.record === 1 ? 'yes-green-span':'no-red-span'">{{ scope.row.record && scope.row.record === 1 ? "有录音" : "无录音" }}</span>
                </div>
                <div v-else-if="scope.row.type === 2">
                  <el-tag size="mini" type="success">双计费</el-tag>
                  <span :class="scope.row.record === 1 ? 'yes-green-span':'no-red-span'">{{ scope.row.record && scope.row.record === 1 ? "有录音" : "无录音" }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="号码行业">
              <template #default="scope">{{ getBusinessTypeText(scope.row.businessType) }}</template>
            </el-table-column>
            <el-table-column label="分配次数">
              <template #default="scope">{{ scope.row.bindNums }}</template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <!-- 页码 -->
    <div class="pager">
      <div>
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[20, 30, 40, 50, 100]" :page-size="searchForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
    <!-- 筛选查询弹窗 -->
    <div>
      <el-dialog title="查询" v-model="theQueryDialog" :close-on-click-modal="false" :destroy-on-close="true" width="32%">
        <div class="">
          <el-form label-position="left" label-width="100px" size="small" :model="searchForm" ref="upform">
            <div class="form-out-box p-0-30">
              <el-form-item label="号码">
                <el-input v-model.trim="searchForm.number" placeholder="请输入查询的号码" clearable></el-input>
              </el-form-item>
              <el-form-item label="地区">
                <el-cascader  style="width: 100%" :options="options" :props="{value: 'name',label: 'name',checkStrictly: true}" v-model="searchArea" @change="searchAreaChange"></el-cascader>
              </el-form-item>
              <!-- <el-form-item label="省份">
                <el-input v-model.trim="searchForm.province" placeholder="请输入查询的省份" clearable></el-input>
              </el-form-item>
              <el-form-item label="城市">
                <el-input v-model.trim="searchForm.city" placeholder="请输入查询的城市" clearable></el-input>
              </el-form-item> -->
              <!-- <el-form-item label="区号">
                <el-input v-model.trim="searchForm.areaCode" placeholder="请输入查询的区号" clearable></el-input>
              </el-form-item> -->
              <!-- <el-form-item label="分配次数">
                <el-input v-model.trim="searchForm.distributionCount" placeholder="请输入查询的次数" clearable></el-input>
              </el-form-item> -->
              <el-form-item label="服务商">
                <el-select v-model="searchForm.amountId" placeholder="请选择查询的服务商" style="width: 100%" clearable filterable @change="changeValues">
                  <el-option v-for="(item, index) in amount_id" :key="index" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="接口类型">
                <el-select v-model="searchForm.interfaceId" placeholder="请选择查询的接口类型" style="width: 100%" clearable filterable>
                  <el-option v-for="(item, index) in interface_id" :key="index" :label="item.name" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="限制行业">
                <el-select v-model="searchForm.businessType" placeholder="请选择查询的限制行业" style="width: 100%" clearable>
                  <el-option v-for="(item, index) in ['非金融', '房产', '家装']" :key="index" :label="item" :value="index">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="分配用户">
                <el-select v-model="searchForm.uid" placeholder="请选择" style="width: 100%" clearable filterable>
                  <el-option v-for="(item, index) in user_list" :key="index" :label="`${item.nickname}(Id:${item.uid})`" :value="item.uid">
                    <div class="flex-nowrap-space-between">
                      <div :class="item.status == 1?'no-red':''">
                        <span>{{ item.nickname }}</span>
                        <span class="ml-5">(Id:{{ item.uid }})</span>
                      </div>
                      <div v-if="item.status == 1" class="no-red">禁</div>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
               <el-form-item label="回收时间">
                <el-date-picker 
                    v-model="valueTime" 
                    type="datetimerange" 
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :clearable="false"
                    value-format="YYYY-MM-DD HH:mm:ss" 
                    size="mini" 
                    style="width: 100%" 
                    :default-time="defaultTime">
                 </el-date-picker>
              </el-form-item>
              <el-form-item label="计费类型">
                <el-radio v-model="searchForm.type" label="">全部</el-radio>
                <el-radio v-model="searchForm.type" label="1">单计费</el-radio>
                <el-radio v-model="searchForm.type" label="2">双计费</el-radio>
              </el-form-item>
              <el-form-item label="是否录音">
                <el-radio v-model="searchForm.record" label="">全部</el-radio>
                <el-radio v-model="searchForm.record" label="0">关闭</el-radio>
                <el-radio v-model="searchForm.record" label="1">开启</el-radio>
              </el-form-item>
            </div>
            <el-form-item>
              <div class="flex-nowrap-flex-end">
                <el-button class="cancel" plain @click="searchTapReset" round>
                  重置
                </el-button>
                <el-button class="confirmAdd" @click="searchTapFun" round>
                  查询
                </el-button>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import number from '@/api/open/privacy/number'
import interfacets from '@/api/open/privacy/interface'
import amountts from '@/api/open/privacy/amount'
import callts from '@/api/open/privacy/call'
import userts from '@/api/web/user'
import { getDate } from '@/utils/timeToChoose.js'
import usernumber from '@/api/open/privacy/userNumber'
import { message } from 'ant-design-vue'
import ImportFile from '@/components/importFile.vue'
import bulkImport from '@/components/bulkImport.vue'
import { IconFont } from '@/utils/iconfont'
import dictionary from '@/api/system/dictionary'
import area from '@/api/open/area/area'
export default {
  name: 'Number',
  components: {
    ImportFile,
    bulkImport,
    IconFont,
  },
  data() {
    const checkNumber = (rule, value, callback) => {
      let num = 0,
        types = null,
        errorItem
      if (value == null) {
        this.showError = false
        callback(new Error('请填写号码 (多个号码请换行输入)'))
      } else if (value.length == 0) {
        this.showError = false
        callback(new Error('请填写号码 (多个号码请换行输入)'))
      } else {
        let newArr = []
        newArr = value.split(/[(\r\n)\r\n]+/)
        newArr.forEach((item, i) => {
          if (
            item.match(
              /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9]|9[0-9]|0[0-9][0-9]|2[0-9]|0[0-9][0-9][0-9])\d{8}$/
            ) == null
          ) {
            // if(item.match(/(010\d{8})|(0[2-9]\d{9})|(13\d{9})|(14[57]\d{8})|(15[0-35-9]\d{8})|(18[0-35-9]\d{8})/) == null){
            // callback(new Error('请输入正确的号码 (请检查第'+(i+1)+"行)"))
            num++
            types = i
            errorItem = item
          }
        })
        if (num != 0) {
          this.showError = true
          this.errorNumber = errorItem
          callback(
            new Error(
              '请输入正确的号码 (请检查第' +
                (types + 1) +
                '行：' +
                errorItem +
                ')'
            )
          )
        } else {
          this.showError = false
          callback()
        }
      }
    }
    return {
      errorNumber: null, //显示错误号码
      showError: false, //显示错误号码
      tableData: [], //数据列表
      centerDialogVisible: false, // 弹窗--操作号码
      centerBranchVisibleOther: false, // 弹窗--回收小号
      centerUploadVisible: false, // 弹窗--文件上传
      centerBindVisible: false, // 弹窗--号码绑定
      centerBranchVisible: false, // 弹窗--分配小号
      allEidtDialog: false, // 弹窗--批量修改
      theQueryDialog: false, //弹窗--查询
      activeNames: [], //折叠面板
      uploadData: {}, // 导入文件携带数据
      tableHeight: '', //表格高度
      operationType: 0, //弹窗提交按钮文字状态
      currentPage: 1, //分页
      total: 0, //总数据条数
      interface_id: [], // 拿到的接口类型
      amount_id: [], // 拿到的通信账户类型
      industryList: [],
      user_list: [], // 用户的集合
      multipleSelection: [], // 选中小号的集合
      valueTime:'',//选中时间段
      defaultTime:
       [
        new Date(2000, 1, 1, 0, 0, 0),
        new Date(2000, 2, 1, 23, 59, 59)
       ], //默认时间 '12:00:00', '08:00:00'
      upform: {
        //添加号码
        number: '', // 号码
        province: '', // 省份
        city: '', // 城市
        areaCode: '', // 区号
        interfaceId: '', // 接口类型
        amountId: '', // 通信账户ID
        status: '', // 号码状态(0:正常/1:禁用)
        type: '', // 小号类型(1:单计费/2:双计费)
        businessType: '', // 号码行业(0:非金融/1:房产/2:家装)
        record: '', // 是否录音
        distributionCount: '', // 分配次数
        checkAll: false,
        checkedCities: ['上海', '北京'],
        cities: ['上海', '北京', '广州', '深圳'],
        isIndeterminate: true,
      },
      importArray: {
        // 导入文件的存储对象
        amountId: '',
        businessType: '',
        fileUrl: '',
        interfaceId: '',
        record: '',
      },
      searchForm: {
        page: 1,
        pageSize: 20,
        //搜索项
        number: '',
        province: '',
        city: '',
        areaCode: '',
        interfaceId: '',
        amountId: '',
        status: '',
        type: '',
        record: '',
        businessType: '',
        distributionCount: '',
        createTimeBegin:'',
        createTimeEnd:''
      },
      allIds: [],
      bind_form: {
        // 绑定号码--表单
        telA: '',
        telB: '',
        telX: '',
        expire: 30,
      },
      branch_form: {
        // 分配号码--表单
        uid: '', // 用户id
        ids: [], // 小号数组
      },
      allEideFrom: {
        ids: [],
        status: '',
        type: '',
        businessType: '',
        record: '',
      },
      errList: [],
      loading: true, // loding 状态启用
      // 时间选择器快捷款项
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date())
            },
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            },
          },
          {
            text: '一周前',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            },
          },
        ],
      },
      rules: {
        number: [{ required: true, validator: checkNumber, trigger: 'blur' }],
        telA: [
          {
            required: true,
            message: '请填写主叫号码',
            trigger: 'blur',
          },
        ],
        telB: [
          {
            required: true,
            message: '请填写被叫号码',
            trigger: 'blur',
          },
        ],
        expire: [
          {
            required: true,
            message: '请选择绑定时间',
            trigger: 'blur',
          },
        ],
        // number: [
        //   {
        //     required: true,
        //     message: '请填写手机号码',
        //     trigger: 'blur',
        //   },
        //   {
        //     min: 11,
        //     max: 11,
        //     message: '手机号码长度不正确',
        //     trigger: 'blur',
        //   },
        // ],
        areaCode: [
          {
            required: true,
            message: '请填写区号',
            trigger: 'blur',
          },
        ],
        amountId: [
          {
            required: true,
            message: '请选择服务商',
            trigger: 'blur',
          },
        ],
        interfaceId: [
          {
            required: true,
            message: '请选择接口类型',
            trigger: 'blur',
          },
        ],
        status: [
          {
            required: true,
            message: '请选择号码状态',
            trigger: 'blur',
          },
        ],
        type: [
          {
            required: true,
            message: '请选择计费类型',
            trigger: 'blur',
          },
        ],
        businessType: [
          {
            required: true,
            message: '请选择号码行业',
            trigger: 'blur',
          },
        ],
        record: [
          {
            required: true,
            message: '请选择是否录音',
            trigger: 'blur',
          },
        ],
        uid: [
          {
            required: true,
            message: '请选择分配的用户',
            trigger: 'blur',
          },
        ],
      },
      recyclingType: 1,
      options: [],
      searchArea:[]
    }
  },
  methods: {
    getAreaList(){
      area.dropDownTreeList().then(res=>{
        if(res.code == 200){
          this.options = res.data
        }
      })
    },
    getBusinessTypeText(type){
      let item = this.industryList.find(e=>e.code == type);
      return item?.value||'--';
    },
    // 选中服务商
    changeValues(val) {
      this.searchForm.interfaceId = ''
      interfacets
        .getInterface({
          amountId: val,
        })
        .then((res) => {
          this.interface_id = res.data
        })
    },
    // 列表多选--操作
    handleSelectionChange(val) {
      let newIds = []
      val.forEach((item) => {
        newIds.push(item.id)
      })
      this.multipleSelection = val
      this.allIds = newIds
    },
    // 获取用户列表
    getUserList() {
      userts.queryList({}).then((res) => {
        this.user_list = res.data
      })
    },
    // 重置搜索项
    searchTapReset(kind) {
      this.searchForm = {
        page: this.currentPage,
        pageSize: 20,
        status: '',
        type: '',
        record: '',
        createTimeBegin:'',
        createTimeEnd:''
      }
      this.valueTime = [getDate(0) + ' 00:00:00', getDate(0) + ' 23:59:59']
      this.searchArea = []
      if (kind == 1) {
        this.getNumberList()
        this.theQueryDialog = false
      }
    },
    // 搜索特殊项
    searchTapFun() {
      this.getNumberList()
      this.theQueryDialog = false
    },
    // 文件导出
    setSearchExport(type) {
      if (type == 0) {
         if (this.valueTime != null) {
        this.searchForm.createTimeBegin = this.valueTime[0]
        this.searchForm.createTimeEnd = this.valueTime[1]
      } else {
        this.searchForm.createTimeBegin = ''
        this.searchForm.createTimeEnd = ''
      }
        message.warning('正在导出...，请在消息模块下载')
        number
          .recycleExport({
            ...this.searchForm,
          })
          .then((res) => {
            if (res.code != 200) {
              message.error(res.message)
            }
          })
      }
      if (type == 1) {
        if (this.multipleSelection.length == 0) {
          message.warning('请勾选需要导出的条目！')
        } else {
          message.warning('正在导出...，请在消息模块下载')
          number
            .recycleExport({
              ids: this.allIds,
            })
            .then((res) => {
              if (res.code != 200) {
                message.error(res.message)
              }
            })
        }
      }
      if (type == 2) {
         if (this.valueTime != null) {
        this.searchForm.createTimeBegin = this.valueTime[0]
        this.searchForm.createTimeEnd = this.valueTime[1]
      } else {
        this.searchForm.createTimeBegin = ''
        this.searchForm.createTimeEnd = ''
      }
        message.warning('正在导出...，请在消息模块下载')
        number
          .recycleExport({
            ...this.searchForm,
          })
          .then((res) => {
            if (res.code != 200) {
              message.error(res.message)
            }
          })
      }
    },
    // 获取接口类型数组
    getApiType() {
      interfacets.seeApiList({}).then((res) => {
        this.interface_id = res.data
      })
    },
    // 获取通信账户类型
    getAmountType() {
      amountts.getAmountList({}).then((res) => {
        this.amount_id = res.data
      })
      dictionary
        .queryName({
          type: 'businessType',
        })
        .then((res) => {
          this.industryList = res.data
        })
    },
    // 获取记录列表
    getNumberList() {
      this.loading = true
        if (this.valueTime != null) {
        this.searchForm.createTimeBegin = this.valueTime[0]
        this.searchForm.createTimeEnd = this.valueTime[1]
      } else {
        this.searchForm.createTimeBegin = ''
        this.searchForm.createTimeEnd = ''
      }
      number
        .recycleList({
          ...this.searchForm,
        })
        .then((res) => {
          this.tableData = res.data.records
          this.loading = false
          this.total = res.data.total
        })
    },
    // 分页--条数
    handleSizeChange(val) {
      this.searchForm.pageSize = val
      this.getNumberList()
    },
    // 分页--页码
    handleCurrentChange(val) {
      this.searchForm.page = val
      this.currentPage = val
      this.getNumberList()
    },
    searchAreaChange(event) {
      this.searchForm.province = event[0].split('');
      this.searchForm.province.splice(-1,1);
      this.searchForm.province = this.searchForm.province.join('');
      if(event[1]){
        this.searchForm.city = event[1].split('');
        this.searchForm.city.splice(-1,1);
        this.searchForm.city = this.searchForm.city.join('');
      }else{
        this.searchForm.city = '';
      }
    },
  },
  mounted() {
    this.valueTime = [getDate(0) + ' 00:00:00', getDate(0) + ' 23:59:59']
    this.getNumberList()
    this.getApiType()
    this.getAmountType()
    this.getUserList()
    this.getAreaList();
  },
  created() {
    this.tableHeight = this.$store.state.tableHeight
  },
}
</script>
<style lang="scss" scoped>
.number {
  padding-right: 10px;
}
.showIdsList {
  min-height: 30px;
  max-height: 260px;
  overflow-y: overlay;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding-left: 16px;
}
.title-buttom {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}
.cancel {
  color: #637dff;
  border: #637dff solid 1px;
}
.confirmAdd {
  background: #637dff;
  color: #fff;
}
.form-out-box {
  height: 400px;
  margin-bottom: 20px;
  overflow-y: overlay;
}
.yes-green-span {
  display: inline-block;
  height: 20px;
  padding: 0 5px;
  line-height: 19px;
  color: #409eff;
  background: #ecf5ff;
  border: 1px solid #ecf5ff;
}
.no-red-span {
  display: inline-block;
  height: 20px;
  padding: 0 5px;
  line-height: 19px;
  background: #f4f4f5;
  border: 1px solid #f4f4f5;
}
.redItem {
  color: #f0142f;
}
.showErrList {
  height: 200px;
  overflow: scroll;
}
::v-deep(.checkbox .el-checkbox) {
  display: block;
}
</style>
